<script>
import Form, { formCreate } from '../../../../../../components/form';
import DetailSummary from '../../../visit_manage/visit_detail/components/detail_summary';
import DetailBasicInfo from './components/detail_basic.vue';

formCreate.component('sfaVisitDetailDetailBasicInfo', DetailBasicInfo);
formCreate.component('sfaVisitDetailDetailSummary', DetailSummary);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      row: null,
      buttons: {
        submit: false,
      },
    };
  },
  async created() {
    const rule = await this.getFormRule('sfa_interview_detail_report_detail');
    const newRule = [];
    rule.forEach((item) => {
      const v = item;
      if (v.field === 'form_add') {
        newRule.push({
          type: 'formAdd',
          field: 'form_add',
          title: '',
          value: {},
          props: {
            type: this.formConfig.type,
          },
        });
      } else {
        newRule.push(v);
      }
    });
    this.rule = newRule;
    this.reload(this.rule);
    if (this.formConfig.row) {
      this.setValue({
        fieldBasic: this.formConfig.row,
        fieldSummary: this.formConfig.row,
      });
    }
  },

  methods: {
    // 提交
    submit() {
      this.$emit('onClose');
    },
  },
};
</script>
