<script>
import TablePage from '../../../../../components/table_page';
import Form from './form';

import * as utils from '../../../utils/index';

export default {
  name: 'table-component',
  extends: TablePage,
  components: {
    Form,
  },
  data() {
    return {
      requestUrl: '/sfa/sfaVisitReportController/visitExecuteInfoReport',
      params: {
        visitBigType: 'HELP_VISIT',
      },
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: 'Full',
      },
    };
  },
  async created() {
    this.searchFormData.yearMonth = utils.dateFormat('YYYY-mm', new Date());
    const colums = await this.getConfigList('sfa_interview_detail_report');
    console.log(colums);
    colums.map((v) => {
      const rowData = v;
      if (rowData.field === 'createDate') { // 提交时间和离店时间一致
        rowData.field = 'outStore';
      }
      return rowData;
    });
  },
  methods: {
    // 判断是否有‘yearMonth’字段
    hasYearMonth() {
      const index = this.searchList.findIndex((item) => item.field === 'yearMonth');
      if (index > -1) {
        return true;
      }
      return false;
    },
    // 获取列表数据前置函数
    beforeGetList() {
      if (this.hasYearMonth()) {
        this.formData.yearMonth = this.searchFormData.yearMonth || null;
      } else if (this.searchFormData.yearMonth) {
        delete this.searchFormData.yearMonth;
      }
      return true;
    },
    /** @override 重写重置按钮 */
    beforeSearchEvent({ $event }) {
      if ($event.type === 'reset' && this.hasYearMonth()) {
        this.searchFormData.yearMonth = utils.dateFormat('YYYY-mm', new Date());
        this.formData.yearMonth = this.searchFormData.yearMonth;
      }
      return true;
    },
    cellClick({ row, column }) {
      if (column.property === 'clientCode' || column.property === 'clientName') {
        this.formConfig = {
          type: 'view',
          row,
        };
        this.modalConfig.title = '查看';
        this.openFull();
      }
    },
    modalClick({ val, row }) {
      this.formName = 'Form';
      this.formConfig = {};
      if (val.code === 'view') {
        this.formConfig = {
          type: val.code,
          id: row.id,
        };
        this.modalConfig.title = '查看';
        this.openFull();
      }
    },
  },
};
</script>
