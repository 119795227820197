var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    { attrs: { "label-width": "150px" } },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "客户编码" } },
                [
                  _c("TextEllipsis", {
                    attrs: { text: _vm.basicInfo.clientCode },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "客户名称" } },
                [
                  _c("TextEllipsis", {
                    attrs: { text: _vm.basicInfo.clientName },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "客户类型" } },
                [
                  _c("TextEllipsis", {
                    attrs: { text: _vm.basicInfo.clientTypeName },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "被协访人员姓名" } },
                [
                  _c("TextEllipsis", {
                    attrs: { text: _vm.basicInfo.coverHelpRealName },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "被协访人员职位名称" } },
                [
                  _c("TextEllipsis", {
                    attrs: { text: _vm.basicInfo.coverHelpPosName },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "被协访人员所属组织" } },
                [
                  _c("TextEllipsis", {
                    attrs: { text: _vm.basicInfo.coverHelpOrgName },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "协访人员姓名" } },
                [
                  _c("TextEllipsis", {
                    attrs: { text: _vm.basicInfo.visitRealName },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "协访人员职位名称" } },
                [
                  _c("TextEllipsis", {
                    attrs: { text: _vm.basicInfo.visitPosName },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "协访人员所属组织" } },
                [
                  _c("TextEllipsis", {
                    attrs: { text: _vm.basicInfo.visitOrgName },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "提交时间" } },
                [
                  _c("TextEllipsis", {
                    attrs: { text: _vm.basicInfo.createDateAll },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }