<template>
  <el-form label-width="150px">
    <el-row>
      <el-col :span="8">
        <el-form-item label="客户编码">
          <TextEllipsis :text="basicInfo.clientCode"></TextEllipsis>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="客户名称">
          <TextEllipsis :text="basicInfo.clientName"></TextEllipsis>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="客户类型">
          <TextEllipsis :text="basicInfo.clientTypeName"></TextEllipsis>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="被协访人员姓名">
          <TextEllipsis :text="basicInfo.coverHelpRealName"></TextEllipsis>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="被协访人员职位名称">
          <TextEllipsis :text="basicInfo.coverHelpPosName"></TextEllipsis>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="被协访人员所属组织">
          <TextEllipsis :text="basicInfo.coverHelpOrgName"></TextEllipsis>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="协访人员姓名">
          <TextEllipsis :text="basicInfo.visitRealName"></TextEllipsis>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="协访人员职位名称">
          <TextEllipsis :text="basicInfo.visitPosName"></TextEllipsis>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="协访人员所属组织">
          <TextEllipsis :text="basicInfo.visitOrgName"></TextEllipsis>
        </el-form-item>
      </el-col>
      <el-col :span="8">
        <el-form-item label="提交时间">
          <TextEllipsis :text="basicInfo.createDateAll"></TextEllipsis>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>

<script>
import TextEllipsis from '../../../../../../../components/text_ellipsis/index.vue';
import request from '../../../../../../../utils/request';

// const clientTypeObj = {
//   dealer: '经销商',
//   batchDealer: '二批商',
//   terminal: '终端',
// };

const isObject = (value) => Object.prototype.toString.call(value) === '[object Object]';
export default {
  components: { TextEllipsis },
  props: {
    value: Object,
  },
  watch: {
    value: {
      async handler(curVal) {
        if (curVal && isObject(curVal)) {
          this.basicInfo = {
            ...this.basicInfo,
            ...curVal,
          };
          if (!curVal.clientTypeName) {
            const clientTypeObj = await this.getDictCode('sfacusType');
            this.basicInfo.clientTypeName = clientTypeObj[curVal.clientType];
          }
        }
      },
    },
  },
  data() {
    return {
      basicInfo: {
        clientCode: '', // 客户编码
        clientName: '', // 客户名称
        clientTypeDesc: '', // 客户类型
        createDateAll: '', // 提交时间
        visitRealName: '', // 人员姓名
        visitPosName: '', // 人员职位
        visitOrgName: '', // 人员组织
      },
    };
  },
  methods: {
    // 获取数据字典数据
    async getDictCode(dictCode) {
      const res = await request.post('/mdm/mdmdictdata/list', {
        dictTypeCode: dictCode,
      });
      const obj = {};
      if (res.result && res.result.length > 0) {
        res.result.map((v) => {
          obj[v.dictCode] = v.dictValue;
          return v;
        });
      }
      return obj;
    },
  },
};
</script>
