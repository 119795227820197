<script>
import TablePage from '../../../../../components/table_page';
import * as utils from '../../../utils/index';

export default {
  extends: TablePage,
  data() {
    return {
      requestUrl: '/sfa/sfaHelpDefenseController/getHelpVisitEvaluateTable',
      /** @desc 搜索框绑定数据 */
      formData: {},
      searchFormData: {},
    };
  },
  methods: {
    // 判断是否有‘yearMonth’字段
    hasYearMonth() {
      const index = this.searchList.findIndex((item) => item.field === 'yearMonth');
      if (index > -1) {
        return true;
      }
      return false;
    },
    // 获取列表数据前置函数
    beforeGetList() {
      if (this.hasYearMonth()) {
        this.formData.yearMonth = this.searchFormData.yearMonth || null;
      } else if (this.searchFormData.yearMonth) {
        delete this.searchFormData.yearMonth;
      }
      return true;
    },
    /** @override 重写重置按钮 */
    beforeSearchEvent({ $event }) {
      if ($event.type === 'reset' && this.hasYearMonth()) {
        this.searchFormData.yearMonth = utils.dateFormat('YYYY-mm', new Date());
        this.formData.yearMonth = this.searchFormData.yearMonth;
      }
      return true;
    },
    /**
     * 根据this.tableData[i].helpScoreDetailList 动态添加列
     * @override 获取列表后置函数
     */
    afterGetList() {
      // 动态添加列
      if (this.tableData.length > 0) {
        // const dynamicColumnLength = this.tableData[0].helpScoreDetailList.length; // 动态列长度等于最长的协访评价列表
        let dynamicColumnLength = 0; // 动态列长度等于最长的协访评价列表
        this.tableData.forEach((item) => {
          const currentLength = item.helpScoreDetailList.length;
          dynamicColumnLength = currentLength > dynamicColumnLength ? currentLength : dynamicColumnLength;
        });
        const columnsByConfig = this.configs.columns; // 配置的列配置
        const lastColumnOrder = parseInt(columnsByConfig[columnsByConfig.length - 1].formorder, 10); // 最后一列显示顺序
        // 动态列长度等于第一列评价明细数组长度
        for (let i = 0; i < dynamicColumnLength; i += 1) {
          this.configs.columns.push({ // 步骤名称字段
            title: '步骤名称',
            field: `stepName${i}`,
            formorder: lastColumnOrder + i + 1, // 显示顺序
          });
          this.configs.columns.push({ // 评价字段
            title: '评价',
            field: `qualifiedName${i}`,
            formorder: lastColumnOrder + i + 1,
          });
        }
        const newTableData = []; // 新的数据
        this.tableData.forEach((item) => {
          newTableData.push({
            ...item,
            helpVisitPassRate: `${item.helpVisitPassRate}%`,
          });
          item.helpScoreDetailList.forEach((subItem, index) => {
            const newTableDataIndex = newTableData.length - 1;
            newTableData[newTableDataIndex][`stepName${index}`] = subItem.stepName;
            newTableData[newTableDataIndex][`qualifiedName${index}`] = subItem.qualifiedName;
          });
        });
        this.tableData = newTableData;
      }
    },
  },
  async created() {
    this.searchFormData.yearMonth = utils.dateFormat('YYYY-mm', new Date());
    await this.getConfigList('sfa_interview_comment_report');
  },
};
</script>
